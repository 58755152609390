import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export const AppContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    animation: ${fadeIn} 1s ease-in-out;
`;

export const ProgressBarContainer = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 10px;
`;

export const ProgressBar = styled.div`
    flex: 1;
    height: 5px;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 2px;
    overflow: hidden;
    position: relative;
`;

export const Progress = styled.div`
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  animation: ${({ $isPlaying }) => keyframes`
    from { width: 0; }
    to { width: 100%; }
  `} 10s linear infinite;
  animation-play-state: ${({ $isPlaying }) => ($isPlaying ? 'running' : 'paused')};
`;

export const BuyButton = styled.button`
  position: absolute;
  bottom: 20px;
  padding: 10px 20px;
  background-color: #ffffff;
  border: none;
  border-radius: 14px;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f6ebe7;
  }
`;

export const SpeakerButton = styled.button`
  position: absolute;
  bottom: 20px;
  right: 14px;
  background: rgba(128, 128, 128, 0.5);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  color: white;
  z-index: 10;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(128, 128, 128, 0.5);
  }
`;

export const SpeakerIcon = styled.div`
  font-size: 16px;
  margin-top: 2px;
  color: white;
  z-index: 11;
`;

export const HeaderContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  z-index: 11;
`;

export const ProfileImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  z-index: 11;
`;

export const HeaderText = styled.div`
  font-size: 12px;
  color: white;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  z-index: 11;
`;