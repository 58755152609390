import React, { useState, useEffect, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import { FaLink, FaVolumeUp, FaVolumeMute } from 'react-icons/fa';
import {
    AppContainer,
    Image,
    ProgressBarContainer,
    ProgressBar,
    Progress,
    BuyButton,
    SpeakerButton, ProfileImage, HeaderContainer, HeaderText, SpeakerIcon
} from './styles.js';

const images = [
    {
        url: 'https://m.daccordpiece.co.kr/web/product/big/202102/34622d7e178d00642ba67654eda7a84a.jpg',
        link: 'https://m.daccordpiece.co.kr/product/white-roses/73/category/1/display/3/',
        audio: 'https://firebasestorage.googleapis.com/v0/b/homegallery-92555.appspot.com/o/web%2Fc5c00344-69f2-4f73-8ef6-375b16499dc3.mp3?alt=media&token=d9207c86-adea-4434-aeb5-706aed3e79ab'
    },
    {
        url: 'https://m.daccordpiece.co.kr/web/product/big/202206/b0520e4e5c2cbe0240a933439d876bc5.jpg',
        link: 'https://m.daccordpiece.co.kr/product/san-severino-marche-no90/546/category/1/display/3/',
        audio: 'https://firebasestorage.googleapis.com/v0/b/homegallery-92555.appspot.com/o/web%2Faa19ebe3-24d9-4043-b590-ae97a3afcea1.mp3?alt=media&token=61809f3d-0e42-4bb4-9222-4e886e147c3c' },
    {
        url: 'https://m.daccordpiece.co.kr/web/product/big/202105/228e174d1d1a79d64197f20d7cbe9fca.jpg',
        link: 'https://m.daccordpiece.co.kr/product/sunset-waves/274/category/24/display/1/',
        audio: 'https://firebasestorage.googleapis.com/v0/b/homegallery-92555.appspot.com/o/web%2F8348a68f-366b-4a78-ac56-477e683f637e.mp3?alt=media&token=d52356f8-d6ba-4c6d-a980-43c13e05d924' },
];


function App() {
    const [index, setIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(true);
    const [isMuted, setIsMuted] = useState(true);
    const timerRef = useRef(null);
    const audioRef = useRef(new Audio(images[0].audio));
    const touchStartRef = useRef(null);

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            setIndex((prevIndex) => (prevIndex + 1) % images.length);
            resetTimer();
        },
        onSwipedRight: () => {
            setIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
            resetTimer();
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    const handleTouchStart = (e) => {
        if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'svg' && e.target.tagName !== 'path') {
            touchStartRef.current = e.touches[0].clientX;
        }
    };

    const handleTouchEnd = (e) => {
        if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'svg' && e.target.tagName !== 'path') {
            const touchEnd = e.changedTouches[0].clientX;
            const touchStart = touchStartRef.current;
            const screenWidth = window.innerWidth;

            // Only proceed if touch is not a swipe
            if (Math.abs(touchEnd - touchStart) < screenWidth * 0.1) {
                if (touchEnd > screenWidth / 2) {
                    setIndex((prevIndex) => (prevIndex + 1) % images.length);
                } else {
                    setIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
                }
                resetTimer();
            }
        }
    };

    const resetTimer = () => {
        setIsPlaying(false);
        clearInterval(timerRef.current);
        timerRef.current = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 10000);
        setIsPlaying(true);
    };

    const toggleMute = () => {
        if (isMuted) {
            audioRef.current.play();
            clearInterval(timerRef.current);
        } else {
            try {
                audioRef.current.pause();
            } catch (error) {
                console.log(error)
            }
            timerRef.current = setInterval(() => {
                setIndex((prevIndex) => (prevIndex + 1) % images.length);
            }, 5000);
        }
        setIsMuted(!isMuted);
    };

    const handleAudioEnded = () => {
        setTimeout(() => {
            setIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000);
    };

    useEffect(() => {
        const audio = audioRef.current;
        audio.addEventListener('ended', handleAudioEnded);

        return () => {
            audio.removeEventListener('ended', handleAudioEnded);
        };
    }, []);

    useEffect(() => {
        audioRef.current.src = images[index].audio;
        if (!isMuted) {
            audioRef.current.play();
            clearInterval(timerRef.current);
        } else {
            timerRef.current = setInterval(() => {
                setIndex((prevIndex) => (prevIndex + 1) % images.length);
            }, 10000);
        }

        return () => {
            clearInterval(timerRef.current);
        };
    }, );

    return (
        <AppContainer
            {...handlers}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
        >
            <HeaderContainer>
                <ProfileImage src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAWlBMVEXg5e/e4u3h5e/h5e/o7PLi5vDi5vBHcEzg4+vh5fDt8Pfw8vns7/bKzdWBlr9rdYtrfaHl6PC9wcvS1d6dqsSYnayIkKR/hpeepretsbyiprFeaIBSWnBDTGWwaLTBAAAACnRSTlP1aLm4HMfjAP4+GOh5JwAAAMxJREFUKJGVkwFuwyAMRemaJo4Nc20KDNLd/5rrliZpaBqtTwghPX2EZWO65sNscuo70wha3MJKb45oX4CtMa+l+Z9EmsFaorgZj5WkUPK3lqtL7FhwJRGS5CSaxDFoobV0UVPUwDlz1lQlJZacS9Dwt9ZJ8gGIwBIIiStTEsaDv45V/G7KYxKma/1AUyWgqXqQZzfwSHqWQ/i6E2LEOnleeJLnz4X3JF9mamktLC2zUMvHntct25+E9+VpT/ayOdI35GC6vt38DdAeuh9veiNBuDXWFAAAAABJRU5ErkJggg==" alt="Profile" />
                <HeaderText>D'ACCORD PIECE</HeaderText>
            </HeaderContainer>
            <ProgressBarContainer>
                {images.map((_, i) => (
                    <ProgressBar key={i}>
                        <Progress $isPlaying={isPlaying && i === index} />
                    </ProgressBar>
                ))}
            </ProgressBarContainer>
            {images.map((image, i) => (
                <Image
                    key={i}
                    src={image.url}
                    alt={`Slide ${i}`}
                    style={{ display: i === index ? 'block' : 'none' }}
                />
            ))}
            <BuyButton onClick={() => window.open(images[index].link, '_blank')}>
                <FaLink /> 더 알아보기
            </BuyButton>
            <SpeakerButton onClick={toggleMute}>
                <SpeakerIcon>
                    {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
                </SpeakerIcon>
            </SpeakerButton>
        </AppContainer>
    );
}

export default App;